import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { get } from "lodash";
import PrismicRichText from "../components/PrismicRichText";
import Link from "./../components/Link";

const Images = ({ slice }) => {
  const renderImages = (items) => {
    return items.map((el, i) => (
      <div key={i}>
        <LazyLoadImage
          className="cover-img "
          src={get(el, "image.url")}
          alt={get(el, "image.alt")}
          effect="opacity"
        />
      </div>
    ));
  };

  return (
    <section>
      {get(slice, "primary.copy[0].text") && (
        <div className="slider-copy">
          <PrismicRichText render={get(slice, "primary.copy")} />
        </div>
      )}
      <div className="grid-cols padding">
        {renderImages(get(slice, "items"))}
      </div>
      {get(slice, "primary.link_for_ig") && (
        <Link
          to={"/" + get(slice, "primary.link_for_ig.uid")}
          url={get(slice, "primary.link_for_ig.url")}
          target={get(slice, "primary.link_for_ig.target")}
        >
          <img
            src={get(slice, "primary.link_for_ig_image.url")}
            alt=""
            presentation="presentation"
            className="ig-img"
          />
        </Link>
      )}
    </section>
  );
};

function ImageSlider(slice) {
  return <Images slice={slice} />;
}

export default ImageSlider;
